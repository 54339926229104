import type { IconMetadataResource, IconResource, IconSvgResource } from "@octopusdeploy/octopus-server-client";
import React from "react";
import BlueprintIconEditLayout from "~/areas/blueprints/BlueprintIconEditLayout";
import styles from "~/areas/projects/components/Process/style.module.less";
import IconEditor, { IconEditorDefaultColor } from "~/components/form/IconEditor/IconEditor";
import { FormSection, Summary } from "~/components/form/Sections/index";
import { ExpandableFormSection, required } from "~/components/form/index";
import { DebounceText } from "~/primitiveComponents/form/Text/Text";
interface EditBlueprintSettingsPageProps {
    iconSvgResources: IconSvgResource[];
    iconMetadata: IconMetadataResource | undefined;
    blueprintSettings: BlueprintSettings;
    onChange: (blueprintSettings: BlueprintSettings) => void;
}
export interface BlueprintSettings {
    Name: string;
    Description?: string;
    Icon?: IconResource;
}
export function EditBlueprintSettingsPage({ iconSvgResources, iconMetadata, onChange, blueprintSettings }: EditBlueprintSettingsPageProps) {
    const setName = (value: string) => onChange({ ...blueprintSettings, Name: value });
    const setDescription = (value: string) => onChange({ ...blueprintSettings, Description: value });
    const setIconId = (value: string) => onChange({
        ...blueprintSettings,
        Icon: {
            Color: blueprintSettings.Icon?.Color ?? IconEditorDefaultColor,
            Id: value,
        },
    });
    const setIconColor = (value: string) => onChange({
        ...blueprintSettings,
        Icon: {
            Id: blueprintSettings.Icon?.Id ?? "",
            Color: value,
        },
    });
    return (<div>
            <FormSection title="Process Blueprint Name">
                <DebounceText value={blueprintSettings.Name} onChange={setName} label="Blueprint name" validate={required("Please enter a name")}/>
            </FormSection>
            <FormSection title="Description">
                <DebounceText value={blueprintSettings.Description ?? ""} label="Process Blueprint Description" onChange={setDescription}/>
            </FormSection>
            <ExpandableFormSection errorKey="logo" title="Logo" summary={iconSummary(blueprintSettings)} help="Choose an icon.">
                {iconSvgResources && iconMetadata ? (<BlueprintIconEditLayout iconEditor={<IconEditor icons={iconSvgResources} iconMetadata={iconMetadata} selectedIconId={blueprintSettings.Icon?.Id ?? ""} selectedIconColor={blueprintSettings.Icon?.Color ?? IconEditorDefaultColor} onIconIdChange={setIconId} onIconColorChange={setIconColor}/>} onTabChange={() => { }}/>) : null}
            </ExpandableFormSection>
        </div>);
}
function iconSummary(blueprintSettings: BlueprintSettings) {
    const component = <div className={styles.nameSummaryContainer}>{blueprintSettings.Icon?.Id ?? ""}</div>;
    return Summary.summary(component);
}
