import { Callout } from "@octopusdeploy/design-system-components";
import type { GitRefResource, VariableSetResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import React from "react";
import type { FailedPermissionCheck } from "~/areas/projects/components/Process/ProcessStepsLayoutTypes";
import { isFailedPermissionCheck } from "~/areas/projects/components/Process/ProcessStepsLayoutTypes";
import type { ProcessIdentifier } from "~/areas/projects/components/Process/types";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import type { FormFieldProps } from "~/components/form";
import CertificateVariableSelect from "~/components/form/CertificateSelect/CertificateVariableSelect";
interface CertificateVariableInputProps extends FormFieldProps<string> {
    gitRef: GitRefResource | undefined;
    label: string;
    defaultValueIndicator: JSX.Element | undefined;
    allowClear: boolean;
    validate?: ((value: string) => string) | ((value: string | null) => string);
    onValidate?(value: string): void;
    processIdentifier: ProcessIdentifier;
    processVariableSet: VariableSetResource | FailedPermissionCheck;
    libraryVariableSets: LoadedLibraryVariableSets[] | FailedPermissionCheck;
    refreshVariables: (() => Promise<void>) | undefined;
}
const CertificateVariableInput: React.FC<CertificateVariableInputProps> = (props) => {
    const { value, gitRef, label, defaultValueIndicator, allowClear, processIdentifier, processVariableSet, libraryVariableSets, refreshVariables, ...rest } = props;
    const formProps = { ...rest, label };
    if (isFailedPermissionCheck(processVariableSet) || isFailedPermissionCheck(libraryVariableSets)) {
        return <Callout title={`${Permission.VariableView} and ${Permission.LibraryVariableSetView} are required to view selected certificate variables`} type={"warning"}/>;
    }
    return (<React.Fragment>
            <CertificateVariableSelect value={value} processIdentifier={processIdentifier} processVariableSet={processVariableSet} libraryVariableSets={libraryVariableSets} gitRef={gitRef} allowClear={allowClear} refreshVariables={refreshVariables} {...formProps}/>
            {defaultValueIndicator}
        </React.Fragment>);
};
CertificateVariableInput.displayName = "CertificateVariableInput"
export default CertificateVariableInput;
