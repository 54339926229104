import { Checkbox, ExternalLink } from "@octopusdeploy/design-system-components";
import { space } from "@octopusdeploy/design-system-tokens";
import type { GitRef } from "@octopusdeploy/octopus-server-client";
import { toGitRefShort } from "@octopusdeploy/octopus-server-client";
import { useState } from "react";
import * as React from "react";
import type { BusyState } from "~/components/BusyFromPromise/BusyFromPromise";
import SaveDialogLayout from "~/components/DialogLayout/SaveDialogLayout";
import { Note, required, Text } from "~/components/form/index";
interface PublishTemplateDialogProps {
    busy: BusyState;
    onSave: (version: string, isPreRelease: boolean) => Promise<void>;
    errors: Error[];
    gitRef: GitRef;
}
export function PublishTemplateDialog({ busy, onSave, errors, gitRef }: PublishTemplateDialogProps) {
    const [publishVersion, setPublishVersion] = useState<string>("");
    const [isPreRelease, setIsPreRelease] = useState<boolean>(false);
    return (<SaveDialogLayout errors={errors} title={`Publish from ${toGitRefShort(gitRef)}`} busy={busy} onSaveClick={async () => {
            await onSave(publishVersion, isPreRelease);
            return true;
        }} saveButtonLabel={"Publish"}>
            <Text label="Template version" value={publishVersion} onChange={setPublishVersion} autoFocus={true} validate={required("Please enter a version")}/>
            <Checkbox value={isPreRelease} onChange={() => setIsPreRelease(!isPreRelease)} label="Pre-release" noMargin={true}/>
            <Note style={{ marginTop: space[8] }}>
                <ExternalLink href="SemVer" label="Semantic versioning"/> is recommended.
            </Note>
        </SaveDialogLayout>);
}
