/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { GitRefResource, VariableSetResource } from "@octopusdeploy/octopus-server-client";
import { ControlType, VariableType } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import type { ProcessIdentifier } from "~/areas/projects/components/Process/types";
import { isBlueprintProcessIdentifier, isDeploymentOrRunbookProcessIdentifier } from "~/areas/projects/components/Process/types";
import type { LoadedLibraryVariableSets } from "~/areas/projects/components/Variables/AllVariables/AllVariables";
import { IconButtonWithTooltip } from "~/components/IconButtonWithTooltip";
import { AddVariableIconButton } from "~/components/form/AddVariableIconButton";
import type FormFieldProps from "~/components/form/FormFieldProps";
import Select from "~/primitiveComponents/form/Select/Select";
import { collateVariableNames } from "~/utils/LoadProjectVariables/loadProjectVariables";
import IconButtonList from "../../IconButtonList/IconButtonList";
import InputWithActions from "../../InputWithActions";
interface CertificateVariableSelectProps extends FormFieldProps<string> {
    gitRef: GitRefResource | undefined;
    allowClear?: boolean;
    disabled?: boolean;
    label?: string | JSX.Element;
    error?: string;
    warning?: string;
    validate?(value: string): string;
    onValidate?(value: string): void;
    processIdentifier: ProcessIdentifier;
    processVariableSet: VariableSetResource;
    libraryVariableSets: LoadedLibraryVariableSets[];
    refreshVariables: (() => Promise<void>) | undefined;
}
function CertificateVariableSelect(props: CertificateVariableSelectProps) {
    const [error, setError] = React.useState<string | undefined>(undefined);
    const handleChange = (certificateVariable: any) => {
        const value = certificateVariable === "" ? null : certificateVariable;
        if (props.validate) {
            const result = props.validate(value);
            setError(result);
            if (props.onValidate) {
                props.onValidate(result);
            }
        }
        if (props.onChange) {
            props.onChange(value);
        }
    };
    const names = collateVariableNames(props.processVariableSet, props.libraryVariableSets, [], [VariableType.Certificate], [ControlType.Certificate]);
    const buttons = [];
    if (props.refreshVariables) {
        buttons.push(<IconButtonWithTooltip toolTipContent="Refresh" icon="Refresh" onClick={props.refreshVariables}/>);
    }
    if (isDeploymentOrRunbookProcessIdentifier(props.processIdentifier)) {
        buttons.push(<AddVariableIconButton projectSlugOrId={props.processIdentifier.projectId} spaceId={props.processIdentifier.spaceId}/>);
    }
    else if (isBlueprintProcessIdentifier(props.processIdentifier)) {
        // TODO: Link to process template variables
    }
    return (<InputWithActions input={<Select label="Select certificate variable" {...props} allowFilter={true} error={error || props.error} onChange={handleChange} items={names.map((v) => ({ value: v, text: v }))}/>} actions={<IconButtonList buttons={buttons}/>}/>);
}
export default CertificateVariableSelect;
