/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { css } from "@emotion/css";
import { ArrowLeftIcon } from "@octopusdeploy/design-system-components";
import { borderRadius, fontSize, fontWeight, space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { ActionHandlerCategory, Permission, type DeploymentTargetResource, type EnvironmentResource, type ProjectResource } from "@octopusdeploy/octopus-server-client";
import type { Url } from "@octopusdeploy/portal-routes";
import { links } from "@octopusdeploy/portal-routes";
import React, { useState } from "react";
import { useAnalyticActionDispatch, useAnalyticGuidedSetupDispatch, useAnalyticTrackedActionDispatch, useCorrelationId } from "~/analytics/Analytics";
import { ProjectStatusBar } from "~/areas/projects/components/ProjectStatus/ProjectStatusBar";
import { projectStatusActions } from "~/areas/projects/components/ProjectStatus/actions";
import type { ProjectStatus } from "~/areas/projects/components/ProjectStatus/useProjectStatus";
import { type ProjectCreatedOption, ProjectInfrastructure } from "~/areas/projects/components/Projects/AddProject";
import { repository } from "~/clientInstance";
import useLocalStorage from "~/hooks/useLocalStorage";
import { type DoBusyTask, useDoBusyTaskEffect } from "../DataBaseComponent";
import InternalLink from "../Navigation/InternalLink";
import { useSpaceAwareNavigation } from "../Navigation/SpaceAwareNavigation/useSpaceAwareNavigation";
import { isAllowed } from "../PermissionCheck/PermissionCheck";
import { CreateEnvironmentsPage } from "./CreateEnvironmentsDialog";
import CreateNewKubernetesCluster from "./CreateNewKubernetesCluster";
import CreateNewProject from "./CreateNewProject";
interface NewProjectWizardProps {
    spaceId: string;
    projectSlug?: string;
    projectGroupId?: string;
    open: boolean;
    fullScreen?: boolean;
    saveProjectOptions?: boolean;
    close: (project: ProjectResource | undefined) => void;
    back?: () => void;
    doBusyTask: DoBusyTask;
    isOnboarding?: boolean;
    skipWizardNavigation?: boolean;
    projectStatus?: ProjectStatus;
    resumingPage?: PageType;
    onResumedPage?: () => void;
}
interface NewProjectWizardState {
    project: ProjectResource;
    projectOption: ProjectCreatedOption;
    environments?: Array<EnvironmentResource>;
    targets?: Array<DeploymentTargetResource>;
}
type PageType = "Project" | "Environments" | "KubernetesCluster" | "ProjectDetailsPage";
const defaultProjectOptions: ProjectCreatedOption = {
    projectId: undefined,
    vcsRedirect: false,
    newlyCreatedProjectRedirect: true,
    infrastructure: undefined,
    step: undefined,
};
function NewProjectWizard({ isOnboarding = true, ...props }: NewProjectWizardProps) {
    const [currentPage, setCurrentPage] = useState<PageType | undefined>(isOnboarding ? "Project" : undefined);
    const [initialPage, setInitialPage] = useState<PageType | undefined>(isOnboarding ? "Project" : undefined);
    const [state, setState] = useState<NewProjectWizardState>();
    const navigate = useSpaceAwareNavigation();
    const [onboardingProjectOption, setOnboardingProjectOption] = useLocalStorage<ProjectCreatedOption>(`onboardingDialog.${props.spaceId}.ProjectOptions`, defaultProjectOptions);
    const [initialTargets, setInitialTargets] = useState<Array<DeploymentTargetResource>>([]);
    const [spaceHasProjects, setSpaceHasProjects] = useState<boolean>(false);
    const correlationId = useCorrelationId();
    const trackAction = useAnalyticTrackedActionDispatch();
    const dispatchAction = useAnalyticActionDispatch();
    const guidedSetupDispatchAction = useAnalyticGuidedSetupDispatch();
    useDoBusyTaskEffect(props.doBusyTask, async () => {
        const project = props.projectSlug ? await repository.Projects.get(props.projectSlug!) : undefined;
        const environments = await repository.Environments.all();
        const projectsInSpace = await repository.UserOnboarding.getProjectsOnboardingForCurrentUser();
        setSpaceHasProjects(!!projectsInSpace.TotalProjectsInSpace);
        const kubernetesTargets = isAllowed({ permission: Permission.MachineView, wildcard: true }) ? (await repository.Machines.list({ deploymentTargetTypes: "KubernetesTentacle,Kubernetes" })).Items : [];
        setInitialTargets(kubernetesTargets);
        const projectOption = isOnboarding && onboardingProjectOption.projectId === project?.Id ? onboardingProjectOption : defaultProjectOptions;
        if (props.projectSlug && project) {
            setState({ project: project, projectOption: projectOption, environments: environments });
            const initialPage = getNextPage(project, projectOption, environments, kubernetesTargets);
            setInitialPage(initialPage);
        }
        else {
            setState((prev) => ({ ...prev!, environments }));
        }
        nextPage(project, projectOption, environments, kubernetesTargets);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const nextPage = (project: ProjectResource | undefined, projectOptions: ProjectCreatedOption | undefined, environments: Array<EnvironmentResource> | undefined, targets: DeploymentTargetResource[] | undefined) => {
        const nextPage = getNextPage(project, projectOptions, environments, targets);
        setCurrentPage(nextPage);
    };
    const previousPage = () => {
        props.back?.();
    };
    const closeAndRedirect = (toProject: boolean = true) => {
        props.projectStatus?.dispatch?.({ type: projectStatusActions.guidedSetupDismissed });
        if (props.skipWizardNavigation) {
            props.close(state?.project);
        }
        else if (!toProject) {
            navigate.navigate(links.projectsPage.generateUrl({ spaceId: props.spaceId }));
            props.close(state?.project);
        }
        else {
            redirectToProject();
        }
    };
    const projectCreated = (project: ProjectResource, option: ProjectCreatedOption) => {
        guidedSetupDispatchAction("Submit New Project Setup Screen", {
            projectId: project.Id,
            correlationId: correlationId,
            projectType: option.infrastructure,
            projectSubType: option.step,
            versionControl: option.vcsRedirect,
            sourcePage: "Project Setup",
        });
        setState((prev) => ({ ...prev!, project: project, projectOption: option }));
        if (props.saveProjectOptions) {
            setOnboardingProjectOption(option);
        }
        nextPage(project, option, state?.environments, state?.targets?.length ? state?.targets : initialTargets);
    };
    const environmentsCreated = async () => {
        const environments = await repository.Environments.all();
        guidedSetupDispatchAction("Submit Environments Setup Screen", { projectId: state?.project.Id, correlationId: correlationId, environments: environments.map((x) => x.Name).join(";"), sourcePage: "Project Setup" });
        setState((prev) => ({ ...prev!, environments }));
        props.projectStatus?.dispatch?.({ type: projectStatusActions.environmentsAdded });
        nextPage(state?.project, state?.projectOption, environments, state?.targets?.length ? state?.targets : initialTargets);
    };
    const environmentsSkipped = async () => {
        guidedSetupDispatchAction("Exit Project Setup", { projectId: state?.project.Id, correlationId: correlationId, screen: "Environments", sourcePage: "Project Setup" });
        props.projectStatus?.dispatch?.({ type: projectStatusActions.environmentsSkipped });
        nextPage(state?.project, state?.projectOption, undefined, undefined);
    };
    const clusterConnected = () => {
        props.projectStatus?.dispatch?.({ type: projectStatusActions.k8sClusterAdded });
        nextPage(state?.project, state?.projectOption, state?.environments, state?.targets);
    };
    const clusterSkipped = () => {
        guidedSetupDispatchAction("Exit Project Setup", { projectId: state?.project.Id, correlationId: correlationId, screen: "Kubernetes Cluster", sourcePage: "Project Setup" });
        props.projectStatus?.dispatch?.({ type: projectStatusActions.k8sClusterSkipped });
        nextPage(state?.project, state?.projectOption, state?.environments, undefined);
    };
    const redirectToProject = () => {
        const redirectUrl = getProjectURL();
        if (redirectUrl) {
            guidedSetupDispatchAction("Project Setup Completed", { projectId: state?.project.Id, correlationId: correlationId, sourcePage: "Project Setup" });
            navigate.navigate(redirectUrl!);
        }
        props.close(state?.project);
    };
    const getProjectURL = (): Url | undefined => {
        if (!state) {
            return;
        }
        if (props.projectSlug && initialPage === "ProjectDetailsPage") {
            return links.deploymentProcessPage.generateUrl({ spaceId: state.project.SpaceId, projectSlug: state.project.Slug }, { newlyCreatedProject: false });
        }
        if (props.projectSlug && initialPage !== "ProjectDetailsPage") {
            return links.deploymentProcessStepsPage.generateUrl({ spaceId: state.project.SpaceId, projectSlug: state.project.Slug }, { stepTemplates: true, newlyCreatedProject: !spaceHasProjects, actionCategory: ActionHandlerCategory.Featured });
        }
        if (state.projectOption.vcsRedirect) {
            return links.projectVersionControlSettingsPage.generateUrl({ spaceId: state.project.SpaceId, projectSlug: state.project.Slug }, { newlyCreatedProject: !spaceHasProjects });
        }
        if (!state.projectOption.infrastructure) {
            return links.deploymentProcessStepsPage.generateUrl({ spaceId: state.project.SpaceId, projectSlug: state.project.Slug }, { stepTemplates: true, newlyCreatedProject: !spaceHasProjects, actionCategory: ActionHandlerCategory.Featured });
        }
        if (state.projectOption.infrastructure && !state.projectOption.step) {
            const stepCategory = getStepCategory(state.projectOption.infrastructure);
            return links.deploymentProcessStepsPage.generateUrl({ spaceId: state.project.SpaceId, projectSlug: state.project.Slug }, { stepTemplates: true, newlyCreatedProject: !spaceHasProjects, actionCategory: stepCategory });
        }
        const targetTags = state?.targets?.flatMap((target) => target.Roles);
        return links.deploymentProcessStepsPage.generateUrl({ spaceId: state.project.SpaceId, projectSlug: state.project.Slug }, { new: true, actionType: state.projectOption.step, parentStepId: undefined, templateId: undefined, newlyCreatedProject: !spaceHasProjects, tags: targetTags });
    };
    const getStepCategory = (projectInfrastructure: ProjectInfrastructure) => {
        switch (projectInfrastructure) {
            case ProjectInfrastructure.AWS:
                return ActionHandlerCategory.Aws;
            case ProjectInfrastructure.Azure:
                return ActionHandlerCategory.Azure;
            case ProjectInfrastructure.Windows:
                return ActionHandlerCategory.WindowsServer;
            case ProjectInfrastructure.Kubernetes:
                return ActionHandlerCategory.Kubernetes;
            default:
                return ActionHandlerCategory.Featured;
        }
    };
    const getNextPage = (project: ProjectResource | undefined, projectOptions: ProjectCreatedOption | undefined, environments: Array<EnvironmentResource> | undefined, targets: DeploymentTargetResource[] | undefined): PageType => {
        if (!project) {
            return "Project";
        }
        else if (!environments?.length && currentPage !== "Environments") {
            return "Environments";
        }
        else if (environments?.length && projectOptions?.step && currentPage !== "KubernetesCluster" && !targets?.length && isAllowed({ permission: Permission.MachineView, wildcard: true })) {
            return "KubernetesCluster";
        }
        else {
            return "ProjectDetailsPage";
        }
    };
    const projectName = !state?.project?.Name ? "" : state.project.Name;
    const projectLogoUrl = state?.project?.Links.Logo;
    const isKubernetesFlow = state?.projectOption?.infrastructure === ProjectInfrastructure.Kubernetes;
    const pages = new Map<PageType, JSX.Element>();
    if (!props.open)
        return null;
    if (props.resumingPage !== undefined) {
        props.onResumedPage?.();
        if (currentPage !== props.resumingPage) {
            setCurrentPage(props.resumingPage);
        }
    }
    if (currentPage === "ProjectDetailsPage" && !props.resumingPage) {
        props.skipWizardNavigation ? props.close(state?.project) : redirectToProject();
    }
    pages.set("Project", <CreateNewProject next={projectCreated} doBusyTask={props.doBusyTask} back={previousPage} close={() => closeAndRedirect(false)} spaceId={props.spaceId} projectGroupId={props.projectGroupId} isOnboarding={isOnboarding} k8sTargetAlreadyExistsInSpace={initialTargets.length > 0} projectStatus={props.projectStatus} spaceHasEnvironments={(state?.environments?.length ?? 0) > 0}/>);
    pages.set("Environments", <CreateEnvironmentsPage isKubernetesFlow={isKubernetesFlow} spaceId={props.spaceId} fullScreen={props.fullScreen} hideBackButton={props.skipWizardNavigation} title={<NewProjectWizardTitle projectName={projectName} projectLogoUrl={projectLogoUrl} spaceId={props.spaceId} clickOnProjectsLink={() => props.close(state?.project)} isOnboarding={isOnboarding}>
                    <ProjectStatusBar state={props.projectStatus?.state} dispatch={props.projectStatus?.dispatch} projectSlug={state?.project?.Slug} spaceId={props.spaceId} hideCloseButton/>
                </NewProjectWizardTitle>} nextButtonTitle={isOnboarding ? "Next" : "Create Environments"} next={async () => await environmentsCreated()} skipButtonTitle="I'll do this later" isOnboarding={isOnboarding} skip={environmentsSkipped} close={closeAndRedirect} trackAction={trackAction} dispatchAction={dispatchAction}/>);
    pages.set("KubernetesCluster", <CreateNewKubernetesCluster projectId={state?.project?.Id} correlationId={correlationId} next={clusterConnected} close={closeAndRedirect} fullScreen={props.fullScreen} hideBackButton={props.skipWizardNavigation} title={<NewProjectWizardTitle projectName={projectName} projectLogoUrl={projectLogoUrl} spaceId={props.spaceId} clickOnProjectsLink={() => props.close(state?.project)} isOnboarding={isOnboarding}>
                    <ProjectStatusBar state={props.projectStatus?.state} dispatch={props.projectStatus?.dispatch} projectSlug={state?.project?.Slug} spaceId={props.spaceId} hideCloseButton/>
                </NewProjectWizardTitle>} environments={state?.environments} isOnboarding={isOnboarding} skip={clusterSkipped} onTargetsChange={(targets) => setState((prev) => ({ ...prev!, targets }))} projectStatus={props.projectStatus}/>);
    if (!currentPage || !pages.has(currentPage))
        return null;
    return pages.get(currentPage)!;
}
interface NewProjectWizardTitleProps {
    projectName: string;
    projectLogoUrl: string | undefined;
    spaceId: string;
    isOnboarding: boolean;
    clickOnProjectsLink: () => void;
    children: React.ReactNode;
}
function NewProjectWizardTitle(props: NewProjectWizardTitleProps) {
    return (<div>
            {!props.isOnboarding && (<InternalLink className={newProjectWizardTitleStyles.projectsLink} to={links.projectsPage.generateUrl({ spaceId: props.spaceId })} onClick={props.clickOnProjectsLink}>
                    <ArrowLeftIcon />
                    <span>Projects</span>
                </InternalLink>)}
            <div className={newProjectWizardTitleStyles.projectContainer}>
                <img className="projectLogo" src={props.projectLogoUrl} alt="Project logo"/>
                {props.projectName}
            </div>
            <div>{props.children}</div>
        </div>);
}
const newProjectWizardTitleStyles = {
    projectsLink: css({
        paddingBottom: space[8],
        display: "flex",
        font: text.regular.default.small,
        gap: space["4"],
    }),
    titleContainer: css({
        display: "flex",
        alignItems: "center",
        marginBottom: space[48],
    }),
    titleIcon: css({
        height: "32px",
        width: "32px",
    }),
    title: css({
        color: themeTokens.color.text.primary,
        fontSize: fontSize["large"],
        fontWeight: fontWeight["400"],
    }),
    projectContainer: css({
        display: "flex",
        alignItems: "center",
        marginBottom: space[16],
        marginTop: space[8],
        textOverflow: "ellipsis",
        color: themeTokens.color.text.primary,
        fontSize: fontSize["base"],
        fontWeight: fontWeight["700"],
        lineHeight: "1.5rem",
        "& .projectLogo": {
            width: "24px",
            height: "24px",
            borderRadius: borderRadius.small,
            backgroundColor: themeTokens.color.background.secondary.default,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: space["8"],
            "& svg": {
                width: "18px",
                height: "18px",
            },
            "& svg path": {
                fill: themeTokens.color.icon.primary,
            },
        },
    }),
};
export default NewProjectWizard;
